import Config from './config.js'

var authFunctions = {
    login: function (username, password, who) {
        var richiesta = new Request(Config.apiBasePath + '/login/' + who, {
          method: 'post',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            username: username,
            password: password,
            package_version: process.env.PACKAGE_VERSION || '0',
          }),
        })
        // ritorna una promise
        var risposta = fetch(richiesta)
        return risposta
    },
    keepToken: function (refreshToken) {
      var richiesta = new Request(Config.apiBasePath + '/' + Config.apiVersion + '/renewAccessToken', {
          method: 'get',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + refreshToken,
          }),
        })
        // ritorna una promise
        var risposta = fetch(richiesta)
        return risposta
    },
}
export default authFunctions
