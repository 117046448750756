<template>
  <v-app>
    <v-container
      id="login-account"
      fluid
      tag="section"
    >
      <v-row justify="center">
        <v-col
          cols="12"
          md="8"
        >
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2 font-weight-light">
                Restricted Area
              </div>

              <div class="subtitle-1 font-weight-light">
                Login with credential
              </div>
            </template>

            <v-form
              v-model="valid"
              lazy-validation
            >
              <v-container class="py-10">
                <v-alert
                  v-show="msgalert"
                  icon="mdi-lock"
                  outlined
                  prominent
                  text
                  type="error"
                >
                  {{ msgalert }}
                </v-alert>
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="model.username"
                      class="purple-input"
                      label="User Name"
                      type="text"
                      append-icon="mdi-account"
                      required
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="model.password"
                      class="purple-input"
                      label="Password"
                      hint="At least 8 characters"
                      :type="showpwd ? 'text' : 'password'"
                      required
                      :rules="[rules.required,rules.min]"
                      :append-icon="showpwd ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showpwd = !showpwd"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="text-right"
                  >
                    <v-btn
                      color="success"
                      class="mr-0"
                      :disabled="!valid"
                      @click="tryLogin"
                    >
                      Login
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row justify="end">
        <span style="color:#ccc">by xspand.iT <small class="ml-4 mr-4"> ver: {{ model.package_version }}</small></span>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import router from '@/router'
  import auth from '../../plugins/auth.js'
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'LoginPage',

    components: {
    },

    data () {
      return {
        msgalert: '',
        model: {
          username: '',
          password: '',
          rememberme: true,
          package_version: process.env.PACKAGE_VERSION || '0',
        },
        loginErrata: false,
        valid: true,
        showpwd: false,
        rules: {
          required: value => !!value || 'Required.',
          min: v => (v && v.length >= 8) || 'Min 8 characters',
        },
      }
    },
    computed: {
      ...mapState(['userinfo']),

      get_userinfo: function () {
        return this.$store.state.userinfo
      },
    },
    methods: {
      ...mapActions([
        'storeUserInfo',
      ]),
      tryLogin: function () {
        if (!this.model.username || this.model.username.trim() === '' || !this.model.password || this.model.password.trim() === '') {
          return
        }
        auth
          .login(this.model.username, this.model.password, 'Agente')
          .then((response) => {
            if (response.status !== 200) {
              this.msgalert = response.status + ' ' + response.statusText
              this.model.username = ''
              this.model.password = ''
              this.model.rememberme = false
              // this.cleanStoreWithUserInformations()
              return undefined
            }

            return response.json()
          })
          .then((jsonData) => {
            if (jsonData !== undefined) {
              this.storeUserInfo(jsonData)
              this.msgalert = 'Pass'
              router.push('/view')
            }
          })
          .catch((errore) => {
            // eslint-disable-next-line no-console
            console.error('ERR', errore)
            this.msgalert = 'General Error'
            this.model.username = ''
            this.model.password = ''
            this.model.rememberme = false
            // this.cleanStoreWithUserInformations()
          })
      },
    },
  }
</script>
